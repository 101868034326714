import styled from 'styled-components';
import {
  mobile_width_percentage,
  tablet_breakpoint,
  tablet_container_maxwidth,
  desktop_breakpoint,
  desktop_breakpoint_extended,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

import chZindex from '../../zIndex';

export const HeaderStyles = styled.div`
  background-color: ${Colors.primary.white.standard};
  width: 100%;

  /* doing all hiding of desktop-only sections here. 
  this could get really confusing mixed with other styling */
  .top-bar-desktop,
  .utility-nav-desktop {
    display: none;
  }
  /* /end of mobile/desktop hiding section! */

  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
  .nav-container {
    box-shadow: ${(props) =>
      props.hideBoxShadow ? 'unset' : '0 2px 13px 0 rgba(0, 0, 0, 0.1)'};
  }
  .nav-main {
    border-bottom: solid 1px ${Colors.primary.black.tint};

    .nav-logo-burger {
      background-color: ${Colors.primary.white.standard};
      border-bottom: solid 1px ${Colors.primary.black.tint};
      display: flex;
      flex-wrap: wrap;
      height: 55px;
      justify-content: space-between;
      position: relative;
      z-index: ${chZindex.header};

      .logo {
        margin: 8px 0 0 14px;

        svg {
          width: 79px;
        }
      }
      .current-location-mobile {
        flex-grow: 1;
      }
      .nav-burger {
        border-left: solid 1px ${Colors.primary.black.tint};
        width: 64px;
        text-align: center;

        img,
        svg {
          height: 40px;
          margin-top: 7px;
          width: 40px;
        }
      }
    }
    .nav-menu-items {
      background-color: ${Colors.primary.white.standard};
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
      margin: 0 auto;
      max-height: calc(100vh - 55px);
      padding: 18px 0 36px 0;
      position: absolute;
      overflow-y: auto;
      transition: margin 600ms ease;
      width: 100%;
      z-index: ${chZindex.navMenuItemsMobile};

      .nav-menu-items-inside {
        margin: 0 auto;
        width: ${mobile_width_percentage}%;

        .nav-menu-main {
          li {
            margin-bottom: 16px;
            padding: 4px;
            text-decoration: none;
            transition: background-color 450ms ease;

            .intro {
              line-height: 0.98;
            }
            a {
              cursor: pointer;
              display: block;
              font-size: 16px;
            }
            .is-active {
              color: ${Colors.primary.claytonBlue.standard};
            }

            &:hover {
              background-color: ${Colors.primary.claytonBlue.tint};
            }
          }
          .nav-account {
            ul {
              display: flex;
              flex-direction: column-reverse;
            }
          }
          .account-status-mobile {
            margin: 16px 0 -23px 0;
            padding-left: 4px;
            li {
              line-height: 0.98;
            }
            .caption {
              font-size: 14px;
              letter-spacing: 0.5px;
            }
            @media only screen and (min-width: ${desktop_breakpoint}px) {
              display: none !important;
            }
          }
          li.my-favorites {
            &:hover {
              background-color: ${Colors.accent.ladyBug.tint};
            }
          }
        }
      }
      &.closed {
        /* This has to account for the size of the mobile menu and size of the alert banners so the closed mobile menu is not visible on the banners */
        margin-top: -1000px;
      }
      ul {
        list-style-type: none;
      }
    }
  }
  @media screen and (min-width: ${tablet_breakpoint}px) {
    .nav-menu-items-inside {
      max-width: ${tablet_container_maxwidth}px;
    }
  }
  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    /* doing all hiding of mobile-only sections, and showing of desktop-only here */
    .top-bar-desktop,
    .nav-menu-main,
    .utility-nav-desktop {
      display: block;
    }
    .nav-burger,
    .current-location-mobile,
    .utility-nav-mobile {
      display: none;
    }
    /* /end of mobile/desktop swapping section! */

    .top-bar-desktop {
      border-bottom: solid 1px ${Colors.primary.black.tint};
      display: flex;
      position: relative;
      background-color: #fff;

      /* For sites that don't need the location/zipcode appearance (hideDomainSpecificActions) */
      .location-placeholder {
        flex-grow: 1;
        position: relative;
        text-align: right;
      }

      .modal-window {
        right: 0;
      }
    }
    .nav-container {
      margin: 0 auto;
      position: relative;
    }
    .nav-main {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;

      .nav-logo-burger {
        border-bottom: 0;

        .logo {
          svg {
            width: 91px;
          }
        }
      }
      .nav-menu-items {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 0;
        right: 0;
        top: 10px;
        width: 100%;
        position: static;

        .nav-menu-items-inside {
          display: flex;
          margin: 0;
          max-width: none;
          width: 100%;

          .nav-menu-main {
            align-items: center;
            border-bottom: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            margin-right: 25px;
            padding-left: 25px;
            width: 100%;

            li {
              margin-bottom: 0;
              padding-bottom: 0;

              .intro {
                line-height: 1.61;
              }
            }

            li:last-child {
              margin-right: 0;
            }
          }
        }
        &.open,
        &.closed {
          margin-top: 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            margin-right: 30px;

            a {
              color: ${Colors.accent.grey1.standard};
              font-size: 16px;
            }
          }
          .divider {
            border-right: solid 1px ${Colors.primary.black.tint};
            height: 28px;
            margin-right: 8px;
            margin-top: ${(props) =>
              props.hideLivePersonMessage ? '3px' : '9px'};
            padding: 0;
            width: 5px;
            display: list-item;
          }
          .my-favorites {
            margin: 0 0 0 27px;

            a {
              margin-top: ${(props) =>
                props.hideLivePersonMessage ? '0px' : '6px'};
            }
          }
          .my-account {
            margin-right: 0;

            a {
              margin-top: ${(props) =>
                props.hideLivePersonMessage ? '0px' : '6px'};
            }

            img,
            svg {
              width: 20px;
            }
          }
          .my-favorites,
          .my-account {
            img,
            svg {
              margin-right: 3px;
              position: relative;
              top: 3px;
            }
          }
        }
        .nav-account {
          ul {
            flex-direction: row !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${desktop_breakpoint_extended - 1}px) {
    .nav-menu-items {
      .divider {
        display: none;
      }
    }
  }
`;
