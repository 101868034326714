import axios from 'axios';
import { getAuthenticationToken } from '@services/tokenService';
import { LAMBDA_TOKEN, getCertAgent, getBaseApiUrl } from './config';

const xHttpAxios = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'lambda-token': LAMBDA_TOKEN,
    'Api-Key': 'f05fcbff-bbb8-41b7-95c3-07e0ef305393',
    ApiKey: '4a7838a0-8698-4088-856a-0f327d9f78c4',
  },
});

const xHttp = {
  get: (url) => {
    const cancelTokenSource = axios.CancelToken.source();

    const axiosPromise = xHttpAxios
      .get(sanitizeUrl(url), {
        cancelToken: cancelTokenSource.token,
        ...getAuthHeader(isBaseApi(url)),
      })
      .then((result) => result.data)
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        }
      });

    axiosPromise.cancel = () => {
      cancelTokenSource.cancel();
    };

    return axiosPromise;
  },

  post: (url, data) => {
    const cancelTokenSource = axios.CancelToken.source();

    const axiosPromise = xHttpAxios
      .post(sanitizeUrl(url), data, {
        cancelToken: cancelTokenSource.token,
        ...getAuthHeader(isBaseApi(url)),
      })
      .then((result) => result.data)
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        }
      });

    axiosPromise.cancel = () => {
      cancelTokenSource.cancel();
    };

    return axiosPromise;
  },

  put: (url, data) => {
    return xHttpAxios
      .put(sanitizeUrl(url), data, getAuthHeader(isBaseApi(url)))
      .then((result) => result.data);
  },

  delete: (url) => {
    return xHttpAxios
      .delete(sanitizeUrl(url), getAuthHeader(isBaseApi(url)))
      .then((result) => result.data);
  },
};

const getAuthHeader = (useHttpsAgent = false) => {
  let bearerToken = getAuthenticationToken();
  bearerToken =
    bearerToken && bearerToken.data ? bearerToken.data : bearerToken;

  let httpsAgent = useHttpsAgent ? getCertAgent() : null;

  if (bearerToken) {
    return {
      headers: { Authorization: 'Bearer ' + bearerToken.access_token },
      httpsAgent: httpsAgent,
    };
  }

  return { httpsAgent };
};

//We are having to check for the DEV and QUA instances, as Base API requires an httpsAgent (But not needed on AWS API urls and will cause
//an error if sent!)
export const isBaseApi = (url) => {
  return url.indexOf(getBaseApiUrl()) !== -1;
};

export const sanitizeUrl = (url) => {
  var cleanedSlashes = url
    .replace(new RegExp('//', 'g'), '/')
    .replace(':/', '://');
  return cleanedSlashes.replace(new RegExp('\\?'), '?');
};

export default xHttp;
