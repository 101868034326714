import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import MenuClose from './images/menu-close.svg';
import MenuBurger from './images/menu-burger.svg';
import HeartSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/heart.svg';
import ClaytonLogo from '@components/Shared/ClaytonLogo/ClaytonLogo';
import { scrollLock, scrollUnlock } from 'pubweb-smokey/dist/utils/utils';
import { HeaderStyles } from './Header.styled';
import { DXAccountContext } from '@contexts/DXAccountContext';
import SectionLink from '../SectionLink/SectionLink';
import SectionNavLink from '../SectionLink/SectionNavLink';
import MobileCTA from './MobileCTA/MobileCTA';
import HeaderLocationIndicator from './HeaderLocationIndicator/HeaderLocationIndicator';
import UtilityNav from './UtilityNav/UtilityNav';
// import LivePersonMessage from '../LivePersonMessage/LivePersonMessage';
// import { LIVEPERSON_ENGAGEMENT } from '@utils/config';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import { PageContext } from '@contexts/PageContext';
import { getSiteUrl } from '@utils/config';
if (
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  !document.getElementById('ccpa_script')
) {
  let source = `https://privacy.claytonhomes.com/ccpa/v5/index.min.js`;
  let script = document.createElement('script');
  script.src = source;
  script.defer = true;
  script.id = 'ccpa_script';
  script.setAttribute('data-class', 'ccpa');
  script.setAttribute('data-id', 'ccpa-banner');
  script.setAttribute('data-first-link-href', getSiteUrl() + '/privacy/');
  // Don't load ccpa banner on brochure page
  if (
    window.location &&
    !window.location.href.toLowerCase().includes('/brochure/')
  ) {
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

//hideDomainSpecificActions is used only on other sites (Landing Pages, Claayton Built) in cases where it does not
//make sense to include them (They can log in, but it's on another domain, etc.)
//accountSettingsOverride is used to show account settings on login from other pages not tied in to the site but may have their own
//login process (Studio)
const Header = ({
  internalLink = true,
  addSiteUrl = false,
  siteUrlOverride = '',
  hideDomainSpecificActions = false,
  getRouter = useRouter,
}) => {
  const [navOpen, setNavOpen] = useState(false);
  const accountContext = useContext(DXAccountContext);
  const pageContext = useContext(PageContext);

  //LivePerson - to see localhost button placement, temporarily set to true.
  // const alwaysShowMessageUs = false;

  const handleLinkClick = () => {
    setNavOpen(false);
    scrollUnlock(); //Set to allow free scrolling again (Clicking "My Favorites" while logged out on mobile)
  };

  /* const handleLivePersonMessageClick = () => {
    if (pageContext.state.livePersonVisible) {
      window.lpTag = window.lpTag || {};
      if (window?.lpTag?.taglets?.rendererStub) {
        window.lpTag.taglets.rendererStub?.click(LIVEPERSON_ENGAGEMENT?.id);
      }
    }
  }; */

  // Callback function when Live Person is initialized.
  useEffect(() => {
    /* window?.lpTag?.events?.bind('lp_sdes', 'VAR_ADDED', () => {
      const engagementInfo =
        window?.lpTag?.taglets?.rendererStub?.getEngagementInfo(
          LIVEPERSON_ENGAGEMENT?.id
        );
      pageContext.actions.setLivePersonVisible(
        !!engagementInfo && Object.keys(engagementInfo).length > 0
      );
    }); */

    return () => {
      scrollUnlock();
    };
  }, []);

  const location = getRouter();
  const isStudioSubNav = location.pathname.includes('/studio/') ? true : false;

  return (
    <HeaderStyles
      hideLivePersonMessage={!pageContext.state.livePersonVisible}
      hideBoxShadow={isStudioSubNav}
    >
      <div className="top-bar-desktop">
        {!hideDomainSpecificActions ? (
          <HeaderLocationIndicator suppressHydrationWarning={true} />
        ) : (
          <div className="location-placeholder"></div>
        )}

        <div className="utilty-nav-desktop">
          <UtilityNav
            internalLink={internalLink}
            addSiteUrl={addSiteUrl}
            siteUrlOverride={siteUrlOverride}
            onLinkClick={handleLinkClick}
          />
        </div>
      </div>

      <div className="nav-container">
        <div className="nav-main">
          <div className="nav-logo-burger">
            <div className="logo">
              <SectionLink
                to="/"
                onClick={handleLinkClick}
                aria-label="Clayton Homes"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
              >
                <ClaytonLogo />
              </SectionLink>
            </div>

            {!hideDomainSpecificActions ? (
              <div className="current-location-mobile">
                <HeaderLocationIndicator />
              </div>
            ) : null}

            <div
              data-test-id="menu-toggle"
              className="nav-burger"
              id="id-nav-burger"
              onClick={() => {
                if (navOpen) {
                  scrollUnlock();
                } else {
                  scrollLock();
                  // If there are alert banners above, this positions the menu to the top of the window when mobile menu is opened.
                  const yPos =
                    window.scrollY +
                    document
                      .getElementById('id-nav-burger')
                      .getBoundingClientRect().top;
                  window.scrollTo(0, yPos);
                }

                setNavOpen(!navOpen);
              }}
            >
              {navOpen ? (
                <MenuClose viewBox="0 0 16 16" />
              ) : (
                <MenuBurger viewBox="0 0 16 16" />
              )}
            </div>
          </div>

          <div className={`nav-menu-items ${navOpen ? 'open' : 'closed'}`}>
            <div className="nav-menu-items-inside">
              <div className="nav-menu-main">
                <div className="nav-primary">
                  <ul>
                    <li>
                      <SectionNavLink
                        onClick={() => {
                          handleLinkClick();
                          pushGTMEvent('ch.primaryNavClick', 'homes_nav', {
                            location: 'Homepage',
                            category: 'home_search',
                            action: 'navigation',
                            label: 'ch.primaryNavClick',
                          });
                        }}
                        to="/find-a-home/"
                        className="standard intro"
                        activeClassName="is-active"
                        internalLink={internalLink}
                        addSiteUrl={addSiteUrl}
                        siteUrlOverride={siteUrlOverride}
                      >
                        Homes
                      </SectionNavLink>
                    </li>
                    <li>
                      <SectionNavLink
                        onClick={handleLinkClick}
                        to="/get-inspired/"
                        className="standard intro"
                        activeClassName="is-active"
                        internalLink={internalLink}
                        addSiteUrl={addSiteUrl}
                        siteUrlOverride={siteUrlOverride}
                      >
                        Inspiration
                      </SectionNavLink>
                    </li>
                    <li>
                      <SectionNavLink
                        onClick={handleLinkClick}
                        to="/about/"
                        className="standard intro"
                        activeClassName="is-active"
                        internalLink={internalLink}
                        addSiteUrl={addSiteUrl}
                        siteUrlOverride={siteUrlOverride}
                      >
                        About
                      </SectionNavLink>
                    </li>
                    <li>
                      <SectionNavLink
                        onClick={handleLinkClick}
                        to="/studio/"
                        className="standard intro"
                        activeClassName="is-active"
                        internalLink={internalLink}
                        addSiteUrl={addSiteUrl}
                        siteUrlOverride={siteUrlOverride}
                      >
                        Studio
                      </SectionNavLink>
                    </li>
                  </ul>
                </div>

                <div className="nav-account">
                  <ul>
                    {/* {pageContext.state.livePersonVisible ||
                    alwaysShowMessageUs ? (
                      <li>
                        <LivePersonMessage
                          handleLivePersonMessageClick={() => {
                            handleLivePersonMessageClick();
                            pushGTMEvent(
                              'ch.livePersonChatbotClick',
                              'liveperson_open_nav',
                              {
                                location: 'Homepage',
                                category: 'chat_bot',
                                action: 'button',
                                label: 'ch.livePersonChatbotClick',
                              }
                            );
                          }}
                        ></LivePersonMessage>
                      </li>
                    ) : null} */}
                    {!accountContext.state.account ? (
                      <>
                        <li className="my-favorites">
                          <a
                            onClick={() => {
                              handleLinkClick();
                              pushGTMEvent(
                                'ch.myFavoritesNavClick',
                                'account_access',
                                {
                                  location: 'Homepage',
                                  category: 'favorites_account',
                                  action: 'navigation',
                                  label: 'ch.myFavoritesNavClick',
                                }
                              );
                              accountContext.actions.navigateToPortal(
                                '/portal/favorites/'
                              );
                            }}
                            className="standard intro"
                            rel="noopener noreferrer"
                          >
                            <>
                              <HeartSvg /> My Favorites
                            </>
                          </a>
                        </li>
                        <li className="divider"></li>
                        <li className="my-account">
                          <a
                            onClick={() => {
                              handleLinkClick();
                              accountContext.actions.navigateToPortal(
                                '/portal/'
                              );
                            }}
                            className="standard intro gtm-my-account"
                            rel="noopener noreferrer"
                          >
                            MyHome Account
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="my-favorites">
                          <SectionNavLink
                            onClick={handleLinkClick}
                            to="/portal/favorites/"
                            className="standard intro"
                            activeClassName="is-active"
                            internalLink={false}
                            addSiteUrl={addSiteUrl}
                            siteUrlOverride={siteUrlOverride}
                          >
                            <>
                              <HeartSvg /> My Favorites
                            </>
                          </SectionNavLink>
                        </li>
                        <li className="divider"></li>
                        <li className="my-account">
                          <SectionNavLink
                            onClick={handleLinkClick}
                            to="/portal/"
                            className="standard intro"
                            activeClassName="is-active"
                            internalLink={false}
                            addSiteUrl={addSiteUrl}
                            siteUrlOverride={siteUrlOverride}
                          >
                            MyHome Account
                          </SectionNavLink>
                          <ul className="account-status-mobile">
                            <li>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  accountContext.actions.accountLogout();
                                }}
                                className="caption"
                                rel="noopener noreferrer"
                              >
                                Log Out
                              </a>
                            </li>
                            <li>
                              <SectionLink
                                onClick={handleLinkClick}
                                to="/portal/account-details/"
                                className="caption"
                                internalLink={internalLink}
                                addSiteUrl={addSiteUrl}
                                siteUrlOverride={siteUrlOverride}
                              >
                                Account Settings
                              </SectionLink>
                            </li>
                          </ul>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="utility-nav-mobile">
              <UtilityNav
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                onLinkClick={handleLinkClick}
              />
            </div>

            <MobileCTA
              internalLink={internalLink}
              addSiteUrl={addSiteUrl}
              siteUrlOverride={siteUrlOverride}
              hideDomainSpecificActions={hideDomainSpecificActions}
            />
          </div>
        </div>
      </div>
    </HeaderStyles>
  );
};

Header.propTypes = {
  accountSettingsOverride: PropTypes.bool,
  addSiteUrl: PropTypes.bool,
  hideDomainSpecificActions: PropTypes.bool,
  internalLink: PropTypes.bool,
  siteUrlOverride: PropTypes.string,
  getRouter: PropTypes.func,
};

export default Header;
