import * as fs from 'fs';
import * as https from 'https';

import React from 'react';

export const BASE_AWS_API_URL =
  process.env.NODE_ENV === 'production'
    ? '#{BASE_AWS_API_URL}'
    : 'https://api.claytonhomes.itg.cct-pubweb.com/';
export const BASE_API_URL =
  process.env.NODE_ENV === 'production'
    ? '#{BASE_API_URL}'
    : 'https://dev.cmhinfo.biz/WebServices2/api/';
export const ENVIRONMENT =
  process.env.NODE_ENV === 'production' ? '#{terraformEnvCode}' : 'dev';

export const CONTENTFUL_API_VERSION = '#{CONTENTFUL_API_VERSION}';
export const INSPIRED_API_VERSION = '#{INSPIRED_API_VERSION}';
export const LANDIT_API_VERSION = '#{LANDIT_API_VERSION}';
export const LOCATIONS_API_VERSION = '#{LOCATIONS_API_VERSION}';
export const RELEASE_VERSION = '#{OctoDeployVersion}';

export const DEFAULT_MAP_CENTER = {
  lat: 37.850033,
  lng: -92.6500523,
};

export const DEFAULT_MAP_ZOOM = 5;

export const SEARCH_DEFAULT_BATHS = 2;
export const SEARCH_MAX_BATHS = 3;
export const SEARCH_DEFAULT_BEDS = 3;
export const SEARCH_MAX_BEDS = 5;
export const SEARCH_DEFAULT_PRICE_LOW = 75000;
export const SEARCH_DEFAULT_PRICE_HIGH = 150000;
export const SEARCH_DEFAULT_DISTANCE = 50;
export const SEARCH_DEFAULT_SORT_METHOD_ID = 4;
export const SEARCH_DEFAULT_MINIMUM_SQUARE_FEET = 0;
export const SEARCH_DEFAULT_MAXIMUM_SQUARE_FEET = 3000;
export const SEARCH_DEFAULT_STARTING_INDEX = 0;
export const SEARCH_DEFAULT_PER_PAGE = 11;
export const SEARCH_ALLOWED_DISTANCES = [
  50, 100, 150, 200, 250, 300, 350, 400, 450, 500,
];

//The number of cities allowed in the dropdown for LocationInput's CityLocationList
export const AUTOCOMPLETE_SEARCH_MAX_LIST = 12;
export const AUTOCOMPLETE_SEARCH_MIN_CHARS = 3;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyB6AeGdWo-bwqqklp6kLGcOENfl3waZzYo';

export const LAMBDA_TOKEN = '#{lambda_token}';

export const STUDIO_URL = '#{STUDIO_URL}';

export const FS_DEV_EXISTS = typeof fs.readFileSync !== 'undefined';

export const ALERT_DISMISS_EXPIRE_MS = 86400000; // Number of milliseconds to wait after alert dismissed before displaying again. (e.g. 3600000 = 60 min, 86400000 = 24 hrs)
export const CH_ALERTS_CLOSED_KEY = 'chAlertsClosed'; // Local storage key for tracking CH.com banner alerts that have been dismissed
export const CH_COM_WEBSITE_BANNER_CONTENTFUL_KEY = '5Q1EgY9jcFAECVZR0KkB3t'; // Contentful key for CH.com Website Banner data
export const SR_WEBSITE_BANNER_CONTENTFUL_KEY = '38MEJGsk9gLudXyN9ZNMN6'; // Contentful key for Social Responsibility Website Banner data
export const NEWSROOM_WEBSITE_BANNER_CONTENTFUL_KEY = 'digsgYGidfOP3XNfh3HVQ'; // Contentful key for Newsroom Website Banner data

export const LIVEPERSON_ENGAGEMENT =
  ENVIRONMENT === 'prod' || ENVIRONMENT === 'qua'
    ? { id: '2482410030', site: '45825951' }
    : { id: '3559676130', site: '55568973' };

export const getCertAgent = () => {
  return (ENVIRONMENT === 'dev' ||
    ENVIRONMENT === 'itg' ||
    ENVIRONMENT === 'qua') &&
    FS_DEV_EXISTS
    ? new https.Agent({
        ca: fs.readFileSync(`./ClaytonRootCA.cer`),
        keepAlive: false,
      })
    : null;
};

export const GTM_ID = 'GTM-N2ZFZ2T';

export const getFacebookAuthAppId = () => {
  let tempEnv = ENVIRONMENT;
  let facebookAppId = '';

  switch (tempEnv) {
    default:
    case 'dev':
    case 'itg':
      facebookAppId = 493875048509420;
      break;
    case 'qua':
      facebookAppId = 204829764851793;
      break;
    case 'staging':
    case 'preview':
    case 'prod':
      facebookAppId = 1192460291230213;
      break;
  }

  return facebookAppId;
};
export const getGoogleAuthAppId = () => {
  let tempEnv = ENVIRONMENT;
  let googleAppId = '';

  switch (tempEnv) {
    default:
    case 'dev':
    case 'itg':
      googleAppId =
        '283083858501-gj3kudim1826e027ga06qq2hjlq5tkat.apps.googleusercontent.com';
      break;
    case 'qua':
      googleAppId =
        '267409802470-hcm74agkn05buh548ftrofjeduq3jpdb.apps.googleusercontent.com';
      break;
    case 'staging':
    case 'preview':
    case 'prod':
      googleAppId =
        '805270524988-o23cl91dphqtsee8bntiqktsipp2n9en.apps.googleusercontent.com';
      break;
  }

  return googleAppId;
};

/* For now, setting the default auth0 parameters to dev since there is not an entry for localhost */
const auth0Properties = {
  dev: {
    domain: 'auth.dev.myhomeaccount.com',
    clientId: 'x00zfFzI2KlQvnwzXtOk6rfXqhJdug1A',
    graphURI: 'https://api-clayton-dx-orchestration.dev.cct-pubweb.com/graphql',
  },
  itg: {
    domain: 'auth.itg.myhomeaccount.com',
    clientId: 'WeGuMNFFcUV3s5FSBMKHNOzlMGmDJGxh',
    graphURI: 'https://api-clayton-dx-orchestration.itg.cct-pubweb.com/graphql',
  },
  qua: {
    domain: 'auth.qua.myhomeaccount.com',
    clientId: 'kOfYMwpVnSRH5AxQ4dqC08eLlD9ZhiDW',
    graphURI: 'https://api-clayton-dx-orchestration.qua.cct-pubweb.com/graphql',
  },
  staging: {
    domain: 'auth.myhomeaccount.com',
    clientId: 'coWSMoUB3aVitc4TmbmZpr22InbWj2bq',
    graphURI: 'https://api.claytonhomes.com/graphql',
  },
  prod: {
    domain: 'auth.myhomeaccount.com',
    clientId: 'coWSMoUB3aVitc4TmbmZpr22InbWj2bq',
    graphURI: 'https://api.claytonhomes.com/graphql',
  },
};

export const getAuth0Domain = auth0Properties[ENVIRONMENT ?? 'dev']?.domain;
export const getAuth0ClientId = auth0Properties[ENVIRONMENT ?? 'dev']?.clientId;
export const getGraphUserURI = auth0Properties[ENVIRONMENT ?? 'dev']?.graphURI;
export const getAuth0Cache = 'localstorage';
export const getAuth0Audience = 'myhome-api';
export const getAuth0Scope =
  'Corp:DX:Read Corp:DX:Write Global:Read:User Global:Write:User profile email';

export const getBaseAwsApiUrl = () => {
  return BASE_AWS_API_URL;
};

export const getBaseApiUrl = () => {
  return BASE_API_URL;
};

export const getLocationsApiVersion = () => {
  if (LOCATIONS_API_VERSION.startsWith('#{LOC')) {
    return 'v2';
  } else {
    return LOCATIONS_API_VERSION;
  }
};

export const getContentfulApiVersion = () => {
  if (CONTENTFUL_API_VERSION.startsWith('#{CON')) {
    return 'v2';
  } else {
    return CONTENTFUL_API_VERSION;
  }
};

export const getInspiredApiVersion = () => {
  if (INSPIRED_API_VERSION.startsWith('#{INS')) {
    return 'v2';
  } else {
    return INSPIRED_API_VERSION;
  }
};

export const getLanditApiVersion = () => {
  if (LANDIT_API_VERSION.startsWith('#{LAN')) {
    return 'v2/landit';
  } else {
    return LANDIT_API_VERSION.trim().startsWith('v2')
      ? 'v2/landit'
      : LANDIT_API_VERSION;
  }
};

export const getSiteUrl = (relativeUrl = '', ssr) => {
  //This is to overstep a local issue where local develop uses the full path automatically.
  if (relativeUrl?.indexOf('localhost') !== -1) {
    return relativeUrl;
  }

  let domain =
    ENVIRONMENT.indexOf('terraformEnvCode') !== -1
      ? 'localhost:3000'
      : `claytonhomes.${ENVIRONMENT}.cct-pubweb.com`;

  if (ssr) {
    domain =
      ENVIRONMENT.indexOf('terraformEnvCode') !== -1
        ? `claytonhomes.${ENVIRONMENT}.cct-pubweb.com`
        : 'localhost:3000';
  }

  if (`${ENVIRONMENT}` == 'dev') {
    domain = 'claytonhomes.dev.cct-pubweb.com';
  }

  if (
    ENVIRONMENT.indexOf('staging') !== -1 ||
    ENVIRONMENT.indexOf('prod') !== -1
  ) {
    domain = 'www.claytonhomes.com';
  }

  return `https://${domain}${relativeUrl}`;
};

export const getCareerSiteUrl = () => {
  let url =
    ENVIRONMENT === '#{terraformEnvCode}' ||
    ENVIRONMENT === 'prod' ||
    ENVIRONMENT === 'preview' ||
    ENVIRONMENT === 'staging'
      ? 'https://careers.claytonhomes.com/'
      : `https://career-sites.${ENVIRONMENT}.cct-pubweb.com`;

  return url;
};

/**
 * Get the link to the learn article
 * @param {string} categoryName category the article belongs to
 * @param {string} url article url slug
 * @returns {string} formatted url
 */
export const getLearnArticleUrl = (url) => {
  if (!url) {
    throw new Error('url is required');
  }

  if (url.indexOf('http') === 0) {
    return url;
  }

  return `/studio/${url}`;
};

export const getTradeDeskIframe = () => {
  return (
    <>
      <iframe
        width="0"
        height="0"
        name="Trade Desk Tracking - Corporate-Request Info Form"
        frameBorder="0"
        scrolling="no"
        src="https://insight.adsrvr.org/tags/1um95m8/x3vd41s/iframe"
      ></iframe>
    </>
  );
};
