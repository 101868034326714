import xHttp from '@utils/xHttp';
import { getBaseAwsApiUrl, getContentfulApiVersion } from '@utils/config';

export const getDynamicPageContent = (key) => {
  let url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/contentful/dpc/${key}`;
  return xHttp.get(url);
};

export const getWebsiteAreaContent = (key) => {
  let url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/contentful/wac/${key}`;
  return xHttp.get(url);
};

export const getCuratedCollection = (slug) => {
  let url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/curated-collections/${slug}`;
  return xHttp.get(url);
};

export const getWebsiteBanner = (key) => {
  let url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/contentful/banner/${key}`;
  return xHttp.get(url);
};

export const getAlertPage = (key) => {
  // Added the 'key' as a query string because it didn't seem to like it as part of the route.
  let url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/contentful/alert/?key=${key}`;
  return xHttp.get(url);
};

export const getHomepage = (key) => {
  let url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/contentful/homepage/${key}`;
  return xHttp.get(url);
};

export const getFullPageControlContentV1 = (key) => {
  const url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/contentful/fccpage/${key}`;
  return xHttp.get(url);
};

export const getFullPageControlContentV2 = (key) => {
  const url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/contentful/fccpageV2/${key}`;
  return xHttp.get(url);
};

export const getWebpageTemplateContent = (key) => {
  let url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/webpage-template/web-template/${key}`;
  return xHttp.get(url);
};

export const getDynamicImageGrid = (modelNumber) => {
  if (!modelNumber) {
    throw new Error('modelNumber is required.');
  }

  const url = `${getBaseAwsApiUrl()}${getContentfulApiVersion()}/webpage-template/dynamic-grid-images?modelNumber=${modelNumber}`;

  return xHttp.get(url, true);
};
