import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Button from '@components/Shared/Buttons/Button';
import {
  getUrlTarget,
  getSlug,
  isUrlInternal,
  getLinkWithUTMParameters,
} from '@utils/utils';

import { PreFooterCTAStyles } from './PreFooterCTA.styled';
import Colors from 'pubweb-smokey/dist/colors';
import { exportUTMParametersFromSessionCache } from 'pubweb-smokey/dist/utils/utils';
import {
  convertButtonColor,
  determineTextColor,
  reverseColors,
  getContentAlignment,
  getButtonAlignment,
  getImageAlignment,
  getClassName,
} from '@utils/ctaUtils';

const PreFooterCTA = ({
  isPreFooterCTA = false,
  buttonLocation,
  backgroundColor,
  buttonColors,
  secondButtonColors,
  image,
  imageAlign,
  contentAlign,
  headingText,
  subHeadingText,
  ctaText,
  customCategory,
  tag,
  buttonText,
  buttonUrl,
  buttonAutomationId,
  buttonOpensToNewTab,
  button2Text,
  button2Url,
  button2AutomationId,
  fullWidth,
  ctaSize,
}) => {
  //One the client-side, cache any utm parameters on this page.  CTA buttons may append this data to their urls.
  const [browserCacheUTM, setBrowserCacheUTM] = useState('');
  useEffect(() => {
    setBrowserCacheUTM(exportUTMParametersFromSessionCache(true));
  }, []);

  //Button Location Options
  let buttonAlign = 'below';

  if (buttonLocation) {
    if (buttonLocation === 'Linked Card-No Button') {
      buttonAlign = 'no-button-linked-card';
    }
    if (buttonLocation === 'Right Side of Text') {
      buttonAlign = 'right';
    }
  }

  const buttonOnDarkBg = buttonColors && reverseColors(buttonColors);
  const btnColor = buttonColors && convertButtonColor(buttonColors);
  const outlineButton =
    btnColor &&
    ((backgroundColor &&
      backgroundColor[0].toLowerCase() === btnColor.standard) ||
      btnColor === Colors.secondary.transparent);

  return (
    <PreFooterCTAStyles
      ctaSize={ctaSize}
      backgroundImageAlign={imageAlign && getImageAlignment(imageAlign)}
      buttonAlignment={getButtonAlignment(buttonAlign, contentAlign)}
      isPreFooterCTA={isPreFooterCTA}
      backgroundColor={backgroundColor && backgroundColor}
      backgroundImage={
        image ? (fullWidth ? `url(${image})` : `url(${image}?w=540)`) : 'none'
      }
      contentAlignment={contentAlign && getContentAlignment(contentAlign)}
      textColor={backgroundColor && determineTextColor(backgroundColor)}
      onClick={() => {
        if (buttonAlign === 'no-button-linked-card') {
          window.location = getLinkWithUTMParameters(
            buttonUrl,
            browserCacheUTM
          );
        }
      }}
      className={
        buttonAlign === 'no-button-linked-card' ? 'cta linked-card' : 'cta'
      }
      borderColor={
        btnColor && btnColor.borderColor
          ? btnColor.borderColor
          : btnColor && btnColor.textColor
            ? btnColor.textColor
            : Colors.primary.white.standard
      }
    >
      <div
        className="cta-inside"
        tabIndex="0"
        role={buttonAlign === 'no-button-linked-card' ? 'button' : ''}
      >
        {customCategory && (
          <p className="label large custom-category">
            {customCategory}{' '}
            {tag && <span className="label large tag"> | {tag}</span>}{' '}
          </p>
        )}

        {headingText && (
          <p
            className={
              isPreFooterCTA
                ? 'h4 cta-heading cta-heading-prefooter'
                : 'h4 cta-heading'
            }
          >
            {headingText}
          </p>
        )}
        {subHeadingText && (
          <p
            className={
              isPreFooterCTA
                ? 'h5 cta-heading cta-heading-prefooter'
                : 'h5 cta-heading'
            }
          >
            {subHeadingText}
          </p>
        )}
        {ctaText && (
          <p
            className={
              isPreFooterCTA ? 'cta-text cta-text-prefooter' : 'cta-text'
            }
          >
            {ctaText}
          </p>
        )}

        {buttonAlign !== 'no-button-linked-card' && (
          <div className="cta-buttons">
            {buttonText && buttonUrl && (
              <Button
                className={`${getClassName(buttonText)} ${
                  outlineButton ? 'cta-button-outline' : ''
                }`}
                primaryColor={btnColor}
                buttonStyle={
                  btnColor === Colors.primary.white ? 'outlined' : null
                }
                onDarkBg={buttonOnDarkBg}
                automationId={buttonAutomationId}
                target={
                  buttonOpensToNewTab ? '_blank' : getUrlTarget(buttonUrl)
                }
                forceExternalLink={!isUrlInternal(buttonUrl)}
                nextJSUrl={
                  isUrlInternal(buttonUrl)
                    ? getSlug(buttonUrl)
                    : getLinkWithUTMParameters(buttonUrl, browserCacheUTM)
                }
                linkComponent={isUrlInternal(buttonUrl) ? Link : null}
              >
                {buttonText}
              </Button>
            )}

            {button2Text && button2Url && (
              <Button
                className={`${getClassName(button2Text)} ${
                  outlineButton ? 'cta-button-outline' : ''
                }`}
                primaryColor={btnColor}
                buttonStyle={
                  btnColor === Colors.primary.white ? 'outlined' : null
                }
                onDarkBg={
                  secondButtonColors === 'Match first color'
                    ? buttonOnDarkBg
                    : !buttonOnDarkBg
                }
                automationId={button2AutomationId}
                target={
                  buttonOpensToNewTab ? '_blank' : getUrlTarget(button2Url)
                }
                forceExternalLink={!isUrlInternal(button2Url)}
                nextJSUrl={
                  isUrlInternal(button2Url)
                    ? getSlug(button2Url)
                    : getLinkWithUTMParameters(button2Url, browserCacheUTM)
                }
                linkComponent={isUrlInternal(buttonUrl) ? Link : null}
              >
                {button2Text}
              </Button>
            )}
          </div>
        )}
      </div>
    </PreFooterCTAStyles>
  );
};

PreFooterCTA.propTypes = {
  backgroundColor: PropTypes.array,
  button2AutomationId: PropTypes.string,
  button2OpensToNewTab: PropTypes.bool,
  button2Text: PropTypes.string,
  button2Url: PropTypes.string,
  buttonAutomationId: PropTypes.string,
  buttonColors: PropTypes.array,
  buttonLocation: PropTypes.string,
  buttonOpensToNewTab: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  contentAlign: PropTypes.string,
  ctaText: PropTypes.string,
  customCategory: PropTypes.any,
  fullWidth: PropTypes.bool,
  headingText: PropTypes.string,
  image: PropTypes.string,
  imageAlign: PropTypes.string,
  isPreFooterCTA: PropTypes.bool,
  secondButtonColors: PropTypes.string,
  subHeadingText: PropTypes.string,
  tag: PropTypes.any,
  ctaSize: PropTypes.string,
};

export default PreFooterCTA;
