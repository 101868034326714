import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'pubweb-smokey/dist/colors';
import ClaytonLogoMobileStyles from './ClaytonLogoMobile.styled.js';

const ClaytonLogoMobile = ({ rooftopColor }) => {
  return (
    <ClaytonLogoMobileStyles
      rooftopColor={
        rooftopColor ? rooftopColor : Colors.accent.claytonGreen.standard
      }
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="31"
      viewBox="0 0 90 31"
    >
      <title>Clayton Homes</title>
      <g fill={rooftopColor} fillRule="evenodd">
        <path
          className="roof"
          d="M31 12.631L54.052 3.958 77.103 12.631 77.103 14.25 54.052 9.484 31 14.25z"
          transform="translate(0 .5)"
        />
      </g>
    </ClaytonLogoMobileStyles>
  );
};

ClaytonLogoMobile.propTypes = {
  rooftopColor: PropTypes.any,
};

export default ClaytonLogoMobile;
