import styled from 'styled-components';

const ClaytonLogoStyles = styled.svg`
  .letter,
  .copyright {
    fill: ${(props) => props.letterColor};
  }
  .roof {
    fill: ${(props) => props.rooftopColor};
  }
`;

export default ClaytonLogoStyles;
